<template>
  <div>
      <svg viewBox="0 0 323 176" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M323 164.992C323 155.386 318.119 147.599 312.097 147.599C308.312 147.599 304.979 150.676 303.024 155.347C302.363 155.073 301.655 154.931 300.94 154.93C299.118 154.93 297.432 155.862 296.058 157.441C294.296 151.617 290.559 147.599 286.234 147.599C282.189 147.599 278.662 151.114 276.78 156.331C275.734 155.45 274.416 154.955 273.048 154.93C268.427 154.93 264.681 160.906 264.681 168.278C264.662 170.736 265.109 173.175 265.999 175.466H320.801C322.181 172.552 323 168.925 323 164.992Z" fill="#7E6A97"/>
<path d="M283.91 64.5513C275.324 46.0036 258.52 30.1808 238.221 27.602C232.853 26.9201 227.249 27.1936 222.27 29.3059C215.733 32.0786 210.915 37.6753 206.046 42.8381C195.737 53.7662 184.2 63.4711 171.664 71.7606C165.477 75.8507 158.841 79.6669 151.496 80.7134C136.768 82.8117 122.2 72.7827 115.483 59.5274C111.287 51.2471 109.348 41.5255 105.956 32.9129C102.05 32.9054 97.396 32.9069 92.3889 32.9299C78.5487 32.9936 67.2833 33.1978 67.2833 33.1978C67.2833 33.1978 80.674 15.4863 92.3386 22.0483C96.0663 24.1453 99.9707 26.0166 103.559 27.593C101.52 23.6776 98.9492 20.1547 95.4119 17.3222C88.4371 11.7371 78.9906 10.1705 70.0598 10.5864C60.6818 11.0232 51.2176 13.5303 43.5972 19.0057C30.0221 28.7599 24.4161 45.9289 19.6182 61.9271L31.2155 67.7326C36.1901 63.4381 43.4643 59.1901 50.1692 62.9619C61.8339 69.5239 75.2336 73.8808 75.2336 73.8808C75.2336 73.8808 64.0596 73.7799 50.2194 73.8435C47.8949 73.8542 45.6459 73.869 43.5078 73.8859L172.266 138.341C214.735 138.938 246.756 136.645 287.111 138.341C289.216 138.429 293.949 127.413 293.965 124.514C294.076 104.081 292.497 83.0991 283.91 64.5513ZM242.674 70.1105C228.833 70.1742 217.568 70.3784 217.568 70.3784C217.568 70.3784 230.959 52.6669 242.623 59.2289C254.288 65.7909 267.688 70.1477 267.688 70.1477C267.688 70.1477 256.514 70.0468 242.674 70.1105Z" fill="#F2F2F2"/>
<path opacity="0.3" d="M278.737 130.726C278.737 123.741 274.406 118.079 269.064 118.079C265.706 118.079 262.749 120.316 261.015 123.712C260.419 123.512 259.795 123.409 259.166 123.409C257.54 123.436 255.988 124.091 254.835 125.235C253.271 121 249.956 118.079 246.119 118.079C242.531 118.079 239.401 120.635 237.732 124.428C236.752 123.771 235.601 123.417 234.421 123.409C230.321 123.409 226.997 127.754 226.997 133.115C226.985 134.923 227.385 136.71 228.167 138.341H276.786C278.09 136.016 278.763 133.39 278.737 130.726Z" fill="#7E6A97"/>
<path d="M323 175.97H24.5239V175.372H322.701L323 175.97Z" fill="#2F2E41"/>
<path d="M298.476 138.938H0V138.341H298.177L298.476 138.938Z" fill="#2F2E41"/>
<path d="M126.697 83.9586C126.697 83.9586 128.577 70.1932 131.71 73.9474C134.843 77.7016 131.71 83.3329 131.71 83.3329L129.203 86.4613L126.697 84.5843V83.9586Z" fill="#FFB8B8"/>
<path d="M119.177 88.3384C119.177 88.3384 125.443 81.4558 126.696 82.7072C127.95 83.9585 131.709 84.5842 131.083 87.087C130.456 89.5898 117.297 97.7239 117.297 97.7239L119.177 88.3384Z" fill="#7E6A97"/>
<path opacity="0.1" d="M119.177 88.3384C119.177 88.3384 125.443 81.4558 126.696 82.7072C127.95 83.9585 131.709 84.5842 131.083 87.087C130.456 89.5898 117.297 97.7239 117.297 97.7239L119.177 88.3384Z" fill="black"/>
<path d="M83.4605 87.7127V92.7183C83.4605 92.7183 82.8339 103.981 86.5936 103.355C90.3533 102.73 88.4734 93.344 88.4734 93.344L87.8468 87.7127H83.4605Z" fill="#FFB8B8"/>
<path d="M85.3407 124.003C85.3407 124.003 79.7012 132.763 77.8214 133.389C75.9416 134.014 65.9159 139.646 64.6626 145.277L55.8901 156.54L60.2764 162.171C60.2764 162.171 80.3278 140.897 85.9673 139.02C91.6068 137.143 97.2462 127.757 97.2462 127.757L85.3407 124.003Z" fill="#FFB8B8"/>
<path d="M129.829 109.612C129.829 109.612 141.735 111.489 139.855 118.998C137.975 126.506 119.804 142.148 119.804 142.148L119.177 145.903L112.284 147.154L109.151 144.026L109.778 142.148C109.778 142.148 117.297 135.266 117.924 132.763C118.55 130.26 125.443 120.249 128.576 119.623L124.19 117.121L129.829 109.612Z" fill="#FFB8B8"/>
<path d="M57.7698 156.539C57.7698 156.539 60.2762 152.785 56.5166 153.411C52.7569 154.037 50.2505 154.662 50.2505 157.165C50.2505 159.668 53.3835 177.188 57.1432 175.936C60.9028 174.685 57.7698 169.053 57.7698 169.053C57.7698 169.053 59.6496 164.048 60.9028 163.422C62.156 162.796 62.156 159.668 60.9028 159.668C59.6496 159.668 57.1432 157.791 57.7698 156.539Z" fill="#2F2E41"/>
<path d="M111.032 142.774C111.032 142.774 111.032 139.646 108.525 142.774C106.019 145.903 104.766 148.405 107.272 150.283C109.778 152.16 118.551 156.54 118.551 156.54C118.551 156.54 127.95 158.417 126.697 154.037C126.697 154.037 120.431 150.908 120.431 148.405C120.431 145.903 120.431 141.523 118.551 142.774C116.671 144.026 111.032 144.651 111.032 142.774Z" fill="#2F2E41"/>
<path d="M99.1258 95.8468C99.1258 95.8468 90.3533 97.0982 90.9799 109.612C90.9799 109.612 79.701 120.875 82.834 124.629C85.967 128.383 92.8597 135.266 95.3661 133.389C97.8726 131.512 105.392 117.121 105.392 117.121C105.392 117.121 122.937 123.378 126.07 120.249C129.203 117.121 132.336 109.612 132.336 109.612L116.671 101.478L99.1258 95.8468Z" fill="#2F2E41"/>
<path d="M123.564 60.1821C127.717 60.1821 131.083 56.8204 131.083 52.6737C131.083 48.5269 127.717 45.1653 123.564 45.1653C119.411 45.1653 116.044 48.5269 116.044 52.6737C116.044 56.8204 119.411 60.1821 123.564 60.1821Z" fill="#FFB8B8"/>
<path d="M117.924 53.2994C117.924 53.2994 113.538 60.1821 112.285 60.8078C111.031 61.4335 121.057 67.6904 121.057 67.6904C121.057 67.6904 121.057 59.5564 124.19 57.6793L117.924 53.2994Z" fill="#FFB8B8"/>
<path d="M117.297 60.1821C117.297 60.1821 107.272 57.0536 105.392 62.0592C103.512 67.0648 99.1258 76.4502 99.7524 82.7072C100.379 88.9642 101.632 87.7128 99.7524 89.5899C97.8726 91.467 96.6194 90.2156 97.246 92.7184C97.8726 95.2211 98.4992 95.2211 97.8726 96.4726C97.246 97.724 105.392 102.104 112.285 102.73L119.177 103.355C119.177 103.355 119.177 92.0927 120.431 89.5899C121.684 87.0871 124.19 82.0815 124.19 80.2044V75.2378C124.19 71.932 123.326 68.6835 121.684 65.8134C121.684 65.8134 117.924 60.1821 117.297 60.1821Z" fill="#7E6A97"/>
<path d="M109.778 62.6849C109.778 62.6849 86.5936 64.562 84.0872 70.8189C81.5808 77.0759 80.3276 89.5899 82.834 89.5899C85.3404 89.5899 88.4735 90.8413 89.1001 89.5899C89.7267 88.3385 92.2331 75.8245 92.8597 75.8245C93.4863 75.8245 112.285 73.9474 112.285 73.9474C112.285 73.9474 120.43 63.9363 109.778 62.6849Z" fill="#7E6A97"/>
<path d="M123.866 50.8123C123.866 50.8123 125.861 47.3497 128.355 50.3572C130.85 53.3648 134.907 47.8379 134.284 46.6585C133.661 45.4792 131.462 46.7115 127.692 45.481C123.922 44.2505 116.661 41.7437 115.445 49.921C114.229 58.0983 117.125 57.241 117.125 57.241C117.125 57.241 118.453 49.995 119.653 52.0764L120.852 54.1578L121.893 54.4135L123.866 50.8123Z" fill="#2F2E41"/>
<path opacity="0.3" d="M58.0204 130.726C58.0204 123.741 53.6896 118.079 48.3473 118.079C44.9895 118.079 42.0322 120.316 40.2978 123.712C39.7022 123.512 39.0778 123.409 38.4492 123.409C36.8235 123.436 35.2715 124.091 34.1178 125.235C32.5544 121 29.2391 118.079 25.4018 118.079C21.8139 118.079 18.6846 120.635 17.0147 124.428C16.0351 123.771 14.8838 123.417 13.7041 123.409C9.60418 123.409 6.28054 127.754 6.28054 133.115C6.26853 134.923 6.66847 136.71 7.4501 138.341H56.0695C57.3733 136.016 58.0458 133.39 58.0204 130.726Z" fill="#7E6A97"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="323" height="176" fill="white"/>
</clipPath>
</defs>
</svg>

  </div>
</template>

<script>
export default {
name:"StepTwoIllustration"
}
</script>

<template>
  <div
    class="flex flex-col justify-between items-center h-full overflow-scroll px-5 py-10">
    <div class="mb-32">
      <SoftLogo />
    </div>
    <div class="w-72 h-72">
      <StepTwoIllustration />
    </div>
    <div class="flex flex-col justify-center items-center">
      <h3 class=" text-center text-lg mb-4">Congratulations on joining the feel
        good
        nation! </h3>
      <p class=" mb-6 text-sm text-center">You have taken your first step in
        making movement your
        gateway to
        feeling better!</p>
      <div class="flex space-x-1 items-center justify-center mb-10">
        <div v-for="item in items" :key="item.id"
          class=" rounded-full h-2 w-2 bg-customPurple"></div>
      </div>
      <router-link class="w-10/12" :to="{ name: 'StepThree' }">
        <MainButton text="Let´s go" class="h-14" />
      </router-link>
    </div>
  </div>
</template>

<script>
import SoftLogo from "@/components/svg/SoftLogo.vue";
import StepTwoIllustration from "@/components/svg/onBoarding/StepTwoIllustration.vue";
import MainButton from "@/components/shared/MainButton.vue";

export default {
  name: "stepOne",

  data() {
    return {
      items: ["Item1", "Item2", "Item3", "Item4", "Item5", "Item6"],
    };
  },

  components: {
    SoftLogo,
    StepTwoIllustration,
    MainButton,
  },
};
</script>
